<template>
   <div class="wrapper">

      <!-- Navbar -->
      <Navbar/>
      <!-- /.navbar -->

      <!-- Main Sidebar Container -->
      <Sidebar v-if="userProfile !== 'client'" />
      <!-- /.main Sidebar Container -->

      <!-- Page content -->
      <router-view/>
      <!-- /.page content -->

      <!-- Footer -->
      <Footer/>
      <!-- /.footer -->
   </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import Sidebar from "./components/Sidebar.vue";
import { mapState } from "vuex";

export default {
   'name': 'Dashboard',
   components: {Sidebar, Navbar, Footer},
   data(){
      return {
         user: {
            full_name: ''
         },
         user_image: '/static/dist/img/user2-160x160.jpg'
      }
   },
   computed: {
      ...mapState({
            userProfile: state => state.auth.user.data.profile
         })
   },
   methods: {
      logout() {
         this.$session.clear();
         localStorage.clear();

         setTimeout(function () {
            window.location.href = '/login';
         }, 2000);
      }
   },
   mounted(){
      if (this.userProfile !== 'client')
         document.querySelector('body').className = "hold-transition sidebar-mini layout-fixed accent-primary layout-navbar-fixed layout-footer-fixed"
      else
         document.querySelector('body').className = "hold-transition sidebar-closed sidebar-collapse layout-fixed accent-primary layout-navbar-fixed layout-footer-fixed"
   },
   watch: {
      'user.user_image': {
         handler(user_image){
            this.user_image = this.$remoteUrl+user_image;
         },
         deep: true
      }
   }
}
</script>
